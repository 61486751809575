import React from "react";
import Link from "next/link";
import styled from "styled-components";
import Image from "next/image";

type IProps = {
    storeName: string;
    url: string;
    title: string;
    width?: number;
    height?: number;
}

const AppDownloadButton = ({
                               storeName,
                               url,
                               title,
                               width = 214,
                               height = 70
                           }: IProps) => {
    return (
        <AppDownloadButtonWrapper key={Math.random()} href={url} target={'_blank'} rel={'noreferrer'} title={title}>
            <Image src={(storeName === 'appStore') ? `/next/app-store.png` : `/next/google-play.png`} alt={title}
                   width={width}
                   height={height}/>
        </AppDownloadButtonWrapper>
    )

}

const AppDownloadButtonWrapper = styled.a`
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all .5s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export default AppDownloadButton;
