import React from "react";
import styled from "styled-components";
import Link from 'next/link';
import Image from "next/image";
import AppDownloadButton from "../buttons/appDownloadButton/AppDownloadButton";
import SocialMediaButton from "../buttons/socialMediaButton/SocialMediaButton";
import TextButton from "../buttons/textButton/TextButton";
import AllRightsReserved from "../allRightReserved/AllRightsReserved";
import QrCodeButton from "../buttons/qrCodeButton/QrCodeButton";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from '../../../styles/device';
import {NavigationURLs} from "../../functions/NavigationFunctions";
import {useAmp} from "next/amp";

export const config = {amp: 'hybrid'}

const Footer = ({}) => {

    const isAmp = useAmp();

    return (
        <FooterWrapper>
            <div className="pp-footer-container">
                <div className="pp-footer-wrapper pp-footer-wrapper__grid">
                    <div className="pp-footer-column">
                        <div className="pp-footer-contact">
                            <div className="logo">
                                {isAmp ? (
                                    <amp-img
                                        src="/next/footer-logo.png"
                                        width="250"
                                        height="70"
                                        layout="fixed"
                                        alt="Pembe Panjur"
                                    />
                                ) : (
                                    <Image src={'/next/footer-logo.png'} width={222} height={62}
                                           alt="Pembe Panjur" title={'Pembe Panjur Logo'}/>
                                )}
                            </div>
                            <div className="pp-footer-shortcuts">
                                <div>
                                    <p>
                                        Bize ulaşabilirsiniz
                                    </p>
                                </div>
                                <div>
                                    <h3>
                                        Destek Hattı E-posta:
                                    </h3>
                                    <Link href={"mailto: bilgi@pembepanjur.com"}>
                                        bilgi@pembepanjur.com
                                    </Link>
                                </div>
                                <div>
                                    <h3>
                                        Destek Hattı Telefon:
                                    </h3>
                                    <Link href={"tel: 08505580808"}>
                                        0 850 558 08 08
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="pp-footer-social-media">
                            {isAmp ? (
                                <React.Fragment>
                                    <a href={"https://www.facebook.com/pembepanjur"} title={'Pembe Panjur Facebook'}>
                                        <amp-img
                                            src="/next/assets/icons/Facebook.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://www.instagram.com/pembepanjur/"} title={'Pembe Panjur Instagram'}>
                                        <amp-img
                                            src="/next/assets/icons/Instagram.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://twitter.com/pembepanjur"} title={'Pembe Panjur Twitter'}>
                                        <amp-img
                                            src="/next/assets/icons/Twitter.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://www.youtube.com/c/pembepanjur"} title={'Pembe Panjur Youtube'}>
                                        <amp-img
                                            src="/next/assets/icons/Youtube.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <SocialMediaButton iconName={"Facebook"}
                                                       url={"https://www.facebook.com/pembepanjur"}
                                                       title={'Pembe Panjur Facebook'}
                                    />
                                    <SocialMediaButton iconName={"Instagram"}
                                                       url={"https://www.instagram.com/pembepanjur/"}
                                                       title={'Pembe Panjur Instagram'}/>
                                    <SocialMediaButton iconName={"Twitter"} url={"https://twitter.com/pembepanjur"}
                                                       title={'Pembe Panjur Twitter'}/>
                                    <SocialMediaButton iconName={"Youtube"}
                                                       url={"https://www.youtube.com/c/pembepanjur"}
                                                       title={'Pembe Panjur Youtube'}/>
                                </React.Fragment>
                            )}
                        </div>

                    </div>
                    <div className="pp-footer-column">
                        <div className="line">
                            <h3>
                                Kısayollar
                            </h3>
                            <div style={{
                                marginBottom: 12
                            }}>
                                <TextButton title={"Hakkımızda"} url={NavigationURLs.about}/>
                                <TextButton title={"Blog"} url={NavigationURLs.blog}/>
                                <TextButton title={"Basın"} url={NavigationURLs.press}/>
                                <TextButton title={"Kariyer"} url={NavigationURLs.career}/>
                                <TextButton title={"Hikayeni Gönder"} url={NavigationURLs.successStorySend}/>
                                <TextButton title={"İletişim"} url={NavigationURLs.contact}/>
                            </div>
                            <h3 style={{
                                marginBottom: 20
                            }}>
                                Şehirler
                            </h3>
                            <div>
                                <TextButton title={"İstanbul Evlilik"} url={NavigationURLs.istanbul} lineHeight={false}/>
                                <TextButton title={"Ankara Evlilik"} url={NavigationURLs.ankara} lineHeight={false}/>
                                <TextButton title={"İzmir Evlilik"} url={NavigationURLs.izmir} lineHeight={false}/>
                                <TextButton title={"Antalya Evlilik"} url={NavigationURLs.antalya} lineHeight={false}/>
                                <TextButton title={"Bursa Evlilik"} url={NavigationURLs.bursa} lineHeight={false}/>
                            </div>
                        </div>
                    </div>
                    <div className="pp-footer-column">
                        <div className="line">
                            <h3>
                                Yardıma İhtiyacınız mı var?
                            </h3>
                            <div>
                                <TextButton title={"Yardım"} url={NavigationURLs.support}/>
                                <TextButton title={"Gizlilik Sözleşmesi"} url={NavigationURLs.privacy}/>
                                <TextButton title={"Üyelik Sözleşmesi"} url={NavigationURLs.membership}/>
                                <TextButton title={"Aydınlatma Metni"} url={NavigationURLs.lightingText}/>
                                <TextButton title={"Çerez Politikası"} url={NavigationURLs.policy}/>
                                <TextButton title={"Kişisel Verilerin Korunması"} url={NavigationURLs.protectionOfPersonalData}/>
                                <TextButton title={"Çağrı Merkezi Aydınlatması"} url={NavigationURLs.callCenterLighting}/>
                            </div>
                        </div>
                    </div>
                    <div className="pp-footer-column">
                        <div className="pp-footer-app-download-section">
                            <h3>
                                Uygulamamızı İndirdiniz mi?
                            </h3>
                            <p>
                                Mutluluk şimdi parmaklarınızın ucunda! Her an her yerden hesabınıza ulaşabilmek için
                                Pembe Panjur uygulamasını hemen indirin.
                            </p>
                            <div className="store-and-qr-section">
                                <div className="store">
                                    {isAmp ? (
                                        <React.Fragment>
                                            <a href={"https://apps.apple.com/tr/app/pembe-panjur-evlilik-tan%C4%B1%C5%9Fma/id708825011?l=tr"}
                                               title={'Pembe Panjur App Store'}>
                                                <amp-img
                                                    src="/next/app-store.png"
                                                    width="214"
                                                    height="70"
                                                    layout="fixed"
                                                    alt="Pembe Panjur App Store"
                                                />
                                            </a>
                                            <a href={"https://play.google.com/store/apps/details?id=com.pembepanjur&hl=tr&gl=US&pli=1"}
                                               title={'Pembe Panjur Google Play'}>
                                                <amp-img
                                                    src="/next/google-play.png"
                                                    width="214"
                                                    height="70"
                                                    layout="fixed"
                                                    alt="Pembe Panjur Google Play"
                                                />
                                            </a>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <AppDownloadButton storeName={'appStore'}
                                                               url={'https://apps.apple.com/tr/app/pembe-panjur-evlilik-tan%C4%B1%C5%9Fma/id708825011?l=tr'}
                                                               title={'Pembe Panjur App Store'} width={175} height={58}/>
                                            <AppDownloadButton storeName={'playStore'}
                                                               url={'https://play.google.com/store/apps/details?id=com.pembepanjur&hl=tr&gl=US&pli=1'}
                                                               title={'Pembe Panjur Google Play'} width={175} height={53}/>
                                        </React.Fragment>
                                    )}


                                </div>
                                <div className="qr">
                                    <div className="qr-background-gradient">
                                        {isAmp ? (
                                            <React.Fragment>
                                                <amp-img
                                                    src="/next/assets/images/qr/qr-pp.png"
                                                    width="112"
                                                    height="112"
                                                    layout="fixed"
                                                    alt="Pembe Panjur Qr Code"
                                                />

                                            </React.Fragment>
                                        ) : (
                                            <QrCodeButton url={'www.pembepanjur.com'}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pp-footer-wrapper">
                    <div className="pp-footer-row">
                        <div className="pp-footer-social-media">
                            {isAmp ? (
                                <React.Fragment>
                                    <a href={"https://www.facebook.com/pembepanjur"} title={'Pembe Panjur Facebook'}>
                                        <amp-img
                                            src="/next/assets/icons/Facebook.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://www.instagram.com/pembepanjur/"} title={'Pembe Panjur Instagram'}>
                                        <amp-img
                                            src="/next/assets/icons/Instagram.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://twitter.com/pembepanjur"} title={'Pembe Panjur Twitter'}>
                                        <amp-img
                                            src="/next/assets/icons/Twitter.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                    <a href={"https://www.youtube.com/c/pembepanjur"} title={'Pembe Panjur Youtube'}>
                                        <amp-img
                                            src="/next/assets/icons/Youtube.svg"
                                            width="47"
                                            height="47"
                                            layout="fixed"
                                            alt="Pembe Panjur"
                                        />
                                    </a>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <SocialMediaButton iconName={"Facebook"}
                                                       url={"https://www.facebook.com/pembepanjur"}
                                                       title={'Pembe Panjur Facebook'}/>
                                    <SocialMediaButton iconName={"Instagram"}
                                                       url={"https://www.instagram.com/pembepanjur/"}
                                                       title={'Pembe Panjur Instagram'}/>
                                    <SocialMediaButton iconName={"Twitter"} url={"https://twitter.com/pembepanjur"}
                                                       title={'Pembe Panjur Twitter'}/>
                                    <SocialMediaButton iconName={"Youtube"}
                                                       url={"https://www.youtube.com/c/pembepanjur"}
                                                       title={'Pembe Panjur Youtube'}/>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="pp-footer-all-rights-reserved">
                            <AllRightsReserved/>
                        </div>
                    </div>
                </div>
            </div>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
  position: relative;
  background: linear-gradient(180deg, #D2147D 0%, #75227E 76.56%, #541D98 99.99%, #D2147D 100%);

  > .pp-footer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 67px 116px 128px;

    @media ${deviceMin.tablet} and ${deviceMax.laptopL} {
      padding-right: 20px;
      padding-left: 20px;
    }

    @media ${deviceMax.tablet} {
      padding: 40px 50px 100px;
    }

    > .pp-footer-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;

      &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        > .pp-footer-column {
          &:nth-child(2) {
            padding-left: 40px;
            
            @media ${deviceMin.tablet} and ${deviceMax.laptop} {
              padding-left: 0;
            }
            
            @media ${deviceMax.tablet} {
              padding-left: 0;
            }
          }  
        }
       
        @media ${deviceMax.tablet} {
          display: flex;
        }
      }
      
      @media ${deviceMax.tablet} {
        flex-direction: column;
      }

      &:first-child {
        border-bottom: 1px solid ${Colors.white};
        margin-bottom: 32px;
        padding-bottom: 36px;

        @media ${deviceMax.tablet} {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      > .pp-footer {
        &-row {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          @media ${deviceMax.tablet} {
            justify-content: center;
          }

          > .pp-footer-social-media {
            position: relative;

            @media ${deviceMin.tablet} and ${deviceMax.laptopL} {
              flex: 1;
            }

            @media ${deviceMax.tablet} {
              display: none;
            }

            > a {
              display: inline-block;
              margin-right: 30px;

              @media ${deviceMin.tablet} and ${deviceMax.laptopL} {
                margin-right: 20px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &-column {
          flex: 1;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
            flex: 1.4;
          }

          @media ${deviceMax.tablet} {
            border-bottom: 1px solid ${Colors.white};
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            padding-bottom: 40px;

            &:nth-child(2) {
              border-bottom: none;
              margin-bottom: 0;
            }

            &:last-child {
              border-bottom: none;
              margin-bottom: 30px;
              padding-bottom: 0;
            }
          }

          > .pp-footer-contact {
            position: relative;
            display: flex;
            flex-direction: column;

            @media ${deviceMax.tablet} {
              text-align: center;
              margin-bottom: 40px;
            }

            > .logo {
              margin-bottom: 30px;
            }

            > .pp-footer-shortcuts {

              > div {
                font-family: 'Manrope', sans-serif;
                font-size: 20px;
                line-height: 32px;
                color: ${Colors.white};
                margin-bottom: 30px;

                &:last-child {
                  margin-bottom: 0;
                }

                > p, > a {
                  font-size: 16px;
                  line-height: 25px;
                  font-weight: 800;
                  margin: 0;

                  @media ${deviceMax.tablet} {
                    margin: 0 auto;
                  }
                }

                > h3 {
                  font-weight: 400;
                  margin-bottom: 0;

                  @media ${deviceMax.tablet} {
                    margin: 0 auto;
                  }
                }

                > a {
                  display: inline-block;
                  transition: all .5s ease;
                  color: ${Colors.white};
                  text-decoration: none;

                  &:hover {
                    opacity: .8;
                    color: ${Colors.white};
                  }
                }
              }
            }
          }

          > .line {
            > h3 {
              font-family: 'Manrope', sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 16px;
              color: ${Colors.white};
              margin-bottom: 30px;
              margin-top: 0;

              @media ${deviceMax.tablet} {
                text-align: center;
                margin: 0 auto 30px;
              }
            }

            > div {
              > a {
                font-size: 16px;
                text-decoration: none;

                &:hover {
                  color: ${Colors.white};
                }

                @media ${deviceMax.tablet} {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }

          > .pp-footer-app-download-section {

            @media ${deviceMax.tablet} {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            > h3 {
              font-family: 'Manrope', sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 25px;
              color: ${Colors.white};
              margin-bottom: 30px;
              margin-top: 0;

              @media ${deviceMax.tablet} {
                font-size: 16px;
                text-align: center;
                margin: 0 auto 30px;
              }

            }

            > p {
              font-family: 'Manrope', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 25px;
              letter-spacing: 0;
              color: ${Colors.white};
              margin-bottom: 30px;

              @media ${deviceMax.tablet} {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                margin: 0 auto 30px;
              }
            }

            > .store-and-qr-section {
              position: relative;
              display: flex;
              flex-direction: row;

              @media ${deviceMin.laptop} and ${deviceMax.laptopL} {
                flex-direction: row;
              }

              @media ${deviceMax.laptop} {
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              > .store {
                position: relative;
                display: flex;
                flex-direction: column;
                margin-right: 18px;

                @media ${deviceMax.laptop} {
                  margin-right: 0;
                  margin-bottom: 22px;
                }

                > a {
                  margin-bottom: 22px;

                  @media ${deviceMax.tablet} {
                    max-width: 180px;

                    > amp-img {
                      max-width: 100%;
                    }
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              > .qr {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${Colors.white};
                border-radius: 20px;
                padding: 14px;
                max-width: 132px;
                max-height: 134px;

                @media ${deviceMax.tablet} {
                  padding: 10px;
                }

                > .qr-background-gradient {
                  display: flex;
                  border-radius: 20px;
                  background-image: linear-gradient(to bottom, #2ed3e1, #27cfe0, #1fcbe0, #18c7df, #10c3de, #09bfdc, #04bad9, #00b6d7, #00b0d3, #00abd0, #00a5cc, #00a0c8);

                  > canvas, amp-img {
                    position: relative;
                    z-index: 2;
                    padding: 14px;
                    max-width: 108px;
                    max-height: 110px;
                  }

                  > amp-img {
                    > img {
                      position: relative;
                      object-fit: contain;
                    }
                  }
                }

              }

            }
          }

          > .pp-footer-social-media {
            display: none;

            @media ${deviceMax.tablet} {
              display: flex;

              > a {
                margin-right: 28px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Footer;
